import { useCallback, useContext, useEffect } from "react";
import { GlobalContext } from "../routes/StateProvider";
import MixpanelEvents from "./mixpanel/MixpanelEvents";

const KEY_ARROW_UP = "ArrowUp";
const KEY_ARROW_DOWN = "ArrowDown";
const KEY_ENTER = "Enter";
const KEY_NUMPAD_ENTER = "NumpadEnter";
const KEY_ESCAPE = "Escape";
const KEY_SLASH = "Slash";

// Global registry of hotkeys we use to toggle things across the page regardless of current
// context.
const FOCUS_CHANGING_HOTKEYS = {
  [KEY_SLASH]: true,
};

// Focus-holding element types that prevent hotkey triggering while focussed.
const FOCUS_HOLDING_ELEMENTS = {
  input: true,
  textarea: true,
};

/**
 * Custom hook to handle hotkeys. Taps into the global hotkey registry to ensure that focus-taking
 * hotkeys are not available when focusable elements are in focus. Example: if we're typing in a
 * text input, we don't want "/" in our text to trigger the global company search.
 *
 * @param hotkeys {object} - Object of hotkeys to register for the component.
 *  Maps key codes to callback functions, in addition to a boolean indicating whether the shift key
 *  being pressed should cancel the callback trigger.
 *  Example:
 *  {
 *    "Enter": {
 *      callback: () => console.log("Enter pressed"),
 *      shiftDisabled: true, // Indicates that if the Shift key is pressed, the callback is not
 *                           // triggered.
 *    },
 *  }
 */
const useHotkeys = (hotkeys) => {
  const {
    state: { hotkeysEnabled: stateHotkeysEnabled },
  } = useContext(GlobalContext);

  const handleKeyDown = useCallback(
    (event) => {
      // hotkeysEnabled only applies to focus-taking hotkeys, so that arrow keys and Enter still work
      // for navigating dropdown lists created by focusable elements.
      if (!(event.code in hotkeys)) {
        return; // Unused key.
      }
      const hotkeysEnabled =
        !FOCUS_HOLDING_ELEMENTS[document.activeElement.localName] &&
        stateHotkeysEnabled;

      if (!hotkeysEnabled && event.code in FOCUS_CHANGING_HOTKEYS) {
        return; // Do nothing.
      }
      if (event.shiftKey && hotkeys[event.code].shiftDisabled) {
        return;
      }
      const success = hotkeys[event.code].callback(event);
      if (success) {
        // eslint thinks this MixpanelEvents.useHotkey is a react hook because of its name
        // eslint-disable-next-line react-hooks/rules-of-hooks
        MixpanelEvents.useHotkey(event.code);
      }
    },
    [hotkeys, stateHotkeysEnabled]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);
};

export {
  KEY_ARROW_DOWN,
  KEY_ARROW_UP,
  KEY_ENTER,
  KEY_NUMPAD_ENTER,
  KEY_ESCAPE,
  KEY_SLASH,
};

export default useHotkeys;
